<template>
  <div>
    <h1>Patients List</h1>
    <v-row justify="end" class="pb-5">
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          :color="color"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="patientHeaders"
      :items="patients"
      :items-per-page="5"
      :search="search"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          x-small
          class="mr-2"
          :to="{ name: 'newPatient', params: { editUser: { ...item } } }"
          title="Click to edit this patient"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          @click="delPatient(item)"
          title="Click to delete this patient"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.created_by="{ value }">
        {{ value }}
      </template>
      <template v-slot:item.modified_by="{ value }">
        {{ value }}
      </template>
      <template v-slot:item.created_on="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.modified_on="{ value }">
        {{ formatDate(value) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <h2 class="success">Maternal And Paternal Demographics</h2>
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="mt-3">Maternal Demographics</h3>
                <h5><b>Address:</b> {{ item.Address }}</h5>
                <h5>
                  <b>Contact Phone Number on case note:</b>
                  {{ item.PhoneNo }}
                </h5>
                <h5><b>Mother&apos;s Age (years):</b> {{ item.MatAge }}</h5>
                <h5><b>Mother&apos;s Parity:</b> {{ item.MatParity }}</h5>
                <h5>
                  <b>Maternal Highest Level of Education:</b>
                  {{ item.MatEdu }}
                </h5>
                <h5>
                  <b>Mother&apos;s Occupation:</b>
                  {{ item.MatOccupation }}
                </h5>
              </v-col>

              <v-col cols="12" md="6">
                <h3 class="mt-4">
                  Maternal Obstetric History and Paternal History
                </h3>
                <h5>
                  <b>Maternal Medical Conditions in Pregnancy:</b>
                  {{ item.MatPregCond }}
                </h5>
                <h5>
                  <b>Previous Pregnancy Loss:</b>
                  {{ item.MatPregLoss }}
                </h5>
                <h5>
                  <b>Previous Neonatal Death:</b>
                  {{ item.MatPrevNeonatalDeath }}
                </h5>
                <h5>
                  <b>Maternal Illness on Admission:</b>
                  {{ item.MatIllnessOnAdmission }}
                </h5>
                <h5><b>ANC:</b> {{ item.Anc }}</h5>
                <h5 v-if="item.Anc == 'Yes'">
                  <b>ANC upto Four Visits:</b>
                  {{ item.AncFourVisits }}
                </h5>
                <h5 v-if="item.Anc == 'Yes'">
                  <b>Place of ANC:</b> {{ item.AncPlace }}
                </h5>
                <h5>
                  <b>Place of Delivery:</b>
                  {{ item.DeliveryPlace }}
                </h5>
                <h5>
                  <b>Mode of Delivery:</b>
                  {{ item.DeliveryMode }}
                </h5>
                <h5
                  v-if="
                    item.DeliveryMode == 'Elective Caesarean Section' ||
                      item.DeliveryMode == 'Emergency Caesarean Section'
                  "
                >
                  <b>C/S indication:</b> {{ item.CSIndication }}
                </h5>
                <h5>
                  <b>Father&apos;s Age (years):</b>
                  {{ item.PatAge }}
                </h5>
                <h5>
                  <b>Father&apos;s Highest Level of Education:</b>
                  {{ item.PatEdu }}
                </h5>
                <h5>
                  <b>Father&apos;s Occupation:</b>
                  {{ item.PatOccupation }}
                </h5>
              </v-col>
            </v-row>

            <h2 class="mt-5 success">Baby&apos;s Data</h2>
            <v-row>
              <v-col cols="12" md="6">
                <h5 class="mt-3"><b>Date of Birth:</b> {{ item.BabyDob }}</h5>
                <h5><b>Date of Admission:</b> {{ item.DateOfAdmission }}</h5>
                <h5><b>Sex:</b> {{ item.Sex }}</h5>
                <h5>
                  <b>Gestational Age (weeks):</b>
                  {{ item.GAInWeeks }}
                </h5>
                <h5>
                  <b>Age at Admission (hours/days if older than 72 hrs):</b>
                  {{
                    item.AdmissionAge
                      ? item.AdmissionAge + " " + item.AdmissionAgeUnit
                      : ""
                  }}
                </h5>
                <h5>
                  <b
                    >Time duration between onset of illness to presentation in
                    facility:</b
                  >
                  {{
                    item.IllnessDuration
                      ? item.IllnessDuration + " " + item.IllnessDurationUnit
                      : ""
                  }}
                </h5>
                <h5>
                  <b>Birth Weight (Kilograms):</b>
                  {{ item.BirthWeight }}
                </h5>
                <h5>
                  <b>Weight at Presentation (Kilograms):</b>
                  {{ item.PresentationWeight }}
                </h5>
                 <h3><b>Morbidities During Admission</b></h3>
                 <h5><b>PDA:</b> {{ item.Pda }}</h5>
                <h5><b>BPD:</b> {{ item.Bpd }}</h5>
                <h5><b>NEC:</b> {{ item.Nec }}</h5>
                <h5><b>LONS:</b> {{ item.Lons }}</h5>
                <h5><b>ROP:</b> {{ item.Rop }}</h5>
                <h5><b>IVH:</b> {{ item.Ivh }}</h5>
                <h5><b>AOP:</b> {{ item.Aop }}</h5>
                <h3><b>Intervention During Admission</b></h3>
                <h5><b>Respiratory Support:</b></h5>
                <h5>CPAP: {{ item.Cpap }}</h5>
                <h5>MV: {{ item.Mv }}</h5>
                <h5>Others: {{ item.Others }}</h5>
                <h5><b>Surfactant:</b> {{ item.Surfactant }}</h5>
                <h5><b>Caffeine:</b> {{ item.Caffeine }}</h5>
                <h5><b>Mannitol:</b> {{ item.Mannitol }}</h5>

              </v-col>
              <v-col cols="12" sm="6">
                <h3 class="mt-2">Presenting Complains</h3>
                <h5>
                  <b>Presenting Complaints:</b>
                  {{
                    item.PresentingComplaints
                      ? item.PresentingComplaints.join(", ")
                      : ""
                  }}
                </h5>
                <h5>
                  <b>General Examination:</b>
                  {{
                    item.GeneralExamination
                      ? item.GeneralExamination.join(", ")
                      : ""
                  }}
                </h5>
                <h5>
                  <b> SPO<sub>2</sub> at Admission (%):</b>
                  {{ item.SPO2OnAdmission }}
                </h5>
                <h5><b>OFC(cm):</b> {{ item.OFC }}</h5>
                <h5><b>Length(cm):</b> {{ item.OFCLength }}</h5>
                <h5><b>Head Lag:</b> {{ item.HeadLag }}</h5>
                <h5><b>Suck:</b> {{ item.Suck }}</h5>
                <h5>
                  <b>Respiratory Distress:</b>
                  {{ item.RespiratoryDistress }}
                </h5>
                <h5 v-if="item.RespiratoryDistress == 'Yes'">
                  <b>
                    Respiratory Evidence:
                  </b>
                  {{
                    item.RespiratoryEvidence
                      ? item.RespiratoryEvidence.join(", ")
                      : ""
                  }}
                </h5>
                <h5>
                  <b>Respiratory Rate:</b>
                  {{ item.RespiratoryRate }}
                </h5>
                <h5><b>Heart Rate:</b> {{ item.HeartRate }}</h5>
                <h5><b>Murmur:</b> {{ item.Murmur }}</h5>
                <h5>
                  <b>Abdomen:</b>
                  {{ item.Abdomen ? item.Abdomen.join(", ") : "" }}
                </h5>
                <h5>
                  <b>Temperature at Admission(<sup>o</sup>C):</b>
                  {{ item.TempOnAdmission }}
                </h5>

                <h5><b>Initial Diagnosis at Admission:</b></h5>
                <h5
                  v-for="n in item.initialDiagnosisNo"
                  :key="'initialDiagnosis' + n"
                >
                  {{ "InitialDiagnosis" + n }}
                  <li>{{ item["InitialDiagnosis" + n] }}</li>
                </h5>
                <h5><b> Other Morbidities Developed on Admission:</b></h5>
                <h5 v-for="m in item.morbiditiesNo" :key="'morbidities' + m">
                  {{ "Morbidity" + m }}
                  <li>{{ item["MorbiditiesOnAdmission" + m] }}</li>
                </h5>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <h2 class="mt-5 success">Investigations</h2>
                <h5 class="mt-2">
                  <b>PCV on Admission(%):</b>
                  {{ item.PCVOnAdmission }}
                </h5>
                <h5>
                  <b> RBS on Admission(mmoL/L):</b>
                  {{ item.RBSOnAdmission }}
                </h5>
                <h5 v-if="item.FBCResult == 'None' || !item.FBCResult">
                  <b>FBC Result:</b> {{ item.FBCResult }}
                </h5>
                <div v-else>
                  <h5>FBC Result</h5>
                  <h5 v-if="item.PCV"><b>PCV(%):</b> {{ item.PCV }}</h5>
                  <h5>
                    <b v-if="item.WBCTotal">WBC Total(x10<sup>9</sup>/L):</b>
                    {{ item.WBCTotal }}
                  </h5>
                  <h5 v-if="item.Neut"><b>Neut(%):</b> {{ item.Neut }}</h5>
                  <h5 v-if="item.Lymph"><b>Lymph(%):</b> {{ item.Lymph }}</h5>
                  <h5 v-if="item.Baso"><b>Baso(%):</b> {{ item.Baso }}</h5>
                  <h5 v-if="item.Eosin"><b>Eosin(%):</b> {{ item.Eosin }}</h5>
                  <h5 v-if="item.Platelete">
                    <b>Platelet(%):</b> {{ item.Platelete }}
                  </h5>
                  <h5 v-if="item.MCV"><b>MCV(%):</b> {{ item.MCV }}</h5>
                  <h5 v-if="item.MCHC"><b>MCHC(%):</b> {{ item.MCHC }}</h5>
                </div>
                <h5>
                  <b>Blood Culture Result:</b>
                  {{ item.BloodCultureResult }}
                </h5>
                <h5 v-if="item.Investigation1">
                  <b>Investigation 1:</b>
                  {{ item.Investigation1 }}
                </h5>
                <h5 v-if="item.Result1">
                  <b>Result 1:</b>
                  {{ item.Result1 }}
                </h5>
                <h5 v-if="item.Investigation2">
                  <b>Investigation 2:</b>
                  {{ item.Investigation2 }}
                </h5>
                <h5 v-if="item.Result2">
                  <b>Result 2:</b>
                  {{ item.Result2 }}
                </h5>

                <h5>
                  <b>Blood Transfusions:</b>
                  {{ item.BloodTransfusion }}
                </h5>
                <h5 v-if="item.BloodTransfusion == 'Yes'">
                  <b>Number of Blood Transfusions:</b>
                  {{ item.BloodTransfusionNumber }}
                </h5>
                <h5 v-if="item.BloodTransfusion == 'Yes'">
                  <b>Indication for Blood Transfusions:</b>
                  {{ item.BloodTransfusionIndication }}
                </h5>
                <h5>
                  <b>Phototherapy during Admission:</b>
                  {{ item.Phototherapy }}
                </h5>
                <h5>
                  <b>Surgery during Admission:</b>
                  {{ item.Surgery }}
                </h5>
                <h5>
                  <b>Incubator Care:</b>
                  {{ item.IncubatorCare }}
                </h5>
                <h5>
                  <b>Number of Antibiotic:</b>
                  {{ item.antibioticNo }}
                </h5>

                <h5 v-for="n in item.antibioticNo" :key="'Antibiotic' + n">
                  {{ "Antibiotic" + n }}
                  <li>Antibiotic: {{ item["Antibiotic" + n] }}</li>
                  <li>Indication: {{ item["AntibioticIndication" + n] }}</li>
                  <li>Duration: {{ item["AntibioticDuration" + n] }}</li>
                </h5>
              </v-col>
              <v-col cols="12" sm="6">
                <h2 class="mt-5 success">Preterm</h2>
                <h5 class="mt-2"><b>Preterm:</b> {{ item.Preterm }}</h5>
                <div v-if="item.Preterm == 'Yes'">
                  <h5>
                    <b
                      >At what postnatal age is birth weight regained (days)?</b
                    >
                    {{ item.PNABirthWeightRegain }}
                  </h5>
                  <h5>
                    <b
                      >At what postnatal age was oral feeds at 120mls/kg
                      achieved(days)?</b
                    >
                    {{ item.PNAOralFeeds }}
                  </h5>

                  <h5>
                    <b>Discharge:</b>
                    {{ item.Discharge }}
                  </h5>
                  <div v-if="item.Discharge == 'Yes'">
                    <h5>
                      <b>Weight at discharge (kilograms):</b>
                      {{ item.WeightAtDischarge }}
                    </h5>
                    <h5>
                      <b>Postnatal age at discharge (days):</b>
                      {{ item.PNAAtDischarge }}
                    </h5>
                    <h5>
                      <b>PCA at discharge:</b>
                      {{ item.PCAAtDischarge }}
                    </h5>
                  </div>
                </div>

                <h2 class="success mt-2">Final Diagnosis</h2>
                <h5 v-if="item.FinalDiagnosis1" class="mt-2">
                  <b>Final Diagnosis 1:</b>
                  {{ item.FinalDiagnosis1 }}
                </h5>
                <h5 v-if="item.FinalDiagnosis2">
                  <b>Final Diagnosis 2:</b>
                  {{ item.FinalDiagnosis2 }}
                </h5>

                <h5>
                  <b>Duration of Admission till discharge/death (days):</b>
                  {{ item.AdmissionDuration ? item.AdmissionDuration : "" }}
                </h5>
                <h5>
                  <b>Outcome of Illness:</b>
                  {{ item.IllnessOutcome }}
                </h5>
                <h5>Outcome Reasons:</h5>
                <b>
                  {{
                    item.IllnessOutcome == "Discharged"
                      ? "State Morbidities at Discharge:"
                      : item.IllnessOutcome == "Death"
                      ? "Possible Causes of Death:"
                      : `State Reason(s) for ${item.IllnessOutcome}:`
                  }}</b
                >{{ item.OutcomeReason }}
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-row v-if="isAdmin" justify="center" class="mt-5">
      <v-col cols="auto" class="mt-n5">
        <b>Enter the year to Download:</b>
        <v-text-field
          v-model="year"
          :color="color"
          placeholder="2019"
          outlined
          filled
          dense
          @input="downloadExcel"
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <vue-excel-xlsx
          :data="patientsdata"
          :columns="columns"
          :file-name="'Patient Lists'"
          :sheet-name="'Patient Sheets'"
          v-if="year"
        >
          <v-btn small rounded class="white--text" :color="color">
            Export to Excel</v-btn
          >
        </vue-excel-xlsx>

        <vue-excel-xlsx
          :data="patientsdataAll"
          :columns="columns"
          :file-name="'Patient Lists'"
          :sheet-name="'Patient Sheets'"
          v-else
        >
          <v-btn small rounded class="white--text" :color="color">
            Export All to Excel</v-btn
          >
        </vue-excel-xlsx>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient, excelData } from "@/services";
import { snackMixin } from "@/mixins";
import { mapGetters } from "vuex";
export default {
  name: "PatientDetails",
  mixins: [snackMixin],
  data() {
    return {
      color: "#024202",
      expanded: [],
      patients: [],
      patientsdata: [],
      patientsdataAll: [],
      search: "",
      columns: excelData,
      year: "",
      patientHeaders: [
        {
          text: "Patient Name",
          align: "start",
          sortable: true,
          filterable: true,
          value: "Name"
        },
        {
          text: "Hospital No",
          sortable: true,
          filterable: true,
          value: "HospNo"
        },
        {
          text: "Created On",
          sortable: true,
          filterable: true,
          value: "created_on"
        },
        {
          text: "Created By",
          sortable: true,
          filterable: true,
          value: "created_by"
        },
        {
          text: "Modified On",
          sortable: true,
          filterable: true,
          value: "modified_on"
        },
        {
          text: "Modified By",
          sortable: true,
          filterable: true,
          value: "modified_by"
        },
        {
          text: "Details",
          value: "data-table-expand"
        },
        {
          text: "",
          value: "actions"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["isAdmin"])
  },
  created() {
    apiClient
      .get("/?u=2")
      .then(res => {
        if (Array.isArray(res.data)) {
          this.patients = [...res.data];
        }
      })
      .catch(err => this.displayMsg(`${err.message}`, "error"));

    apiClient
      .get("/?typeofget=excelall")
      .then(res => {
        if (Array.isArray(res.data)) {
          this.patientsdataAll = [...res.data];
        }
      })
      .catch(err => this.displayMsg(`${err.message}`, "error"));
  },
  methods: {
    downloadExcel() {
      if (this.year.length == 4) {
        apiClient
          .get("/?typeofget=excel&year=" + this.year)
          .then(res => {
            if (Array.isArray(res.data)) {
              this.patientsdata = [...res.data];
              this.patientsdata.length == 0
                ? this.displayMsg(`No Data For The Year ${this.year}`)
                : "";
            }
          })
          .catch(err => this.displayMsg(`${err.message}`, "error"));
      }
    },

    formatDate(dateValue) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      const dt = new Date(dateValue);
      // return `${dt.getDate()}-${dt.getMonth()}-${dt.getFullYear()}`
      return `${months[dt.getMonth()]} ${dt.getDate()}, ${dt.getFullYear()}`;
    },

    delPatient(patient) {
      const ok = confirm(
        `Are sure you want to delete ${patient.Name} with Hospital No: ${patient.HospNo}?`
      );
      if (ok) {
        this.displayMsg(`Deleting ${patient.Name}, please wait...`);
        apiClient
          .delete(`/?${patient.id}`)
          .then(res => {
            if (!res.data.ok) {
              this.displayMsg(
                `${patient.Name} not successfully deleted`,
                "error"
              );
              return;
            }
            this.patients = this.patients.filter(pt => pt.id !== patient.id);
            this.displayMsg(`${patient.Name} successfully deleted`);
          })
          .catch(err => this.displayMsg(`${err.message}`, "error"));
      }
    }
  }
};
</script>
